import React from "react"
import coupon from "../images/coupon.jpg"
import * as sessionStorage from "sessionstorage"
const Coupon = () => {
    const denied = sessionStorage.getItem('couponDenied');
    const [couponShown, setCouponShown] = React.useState(!denied);
    const [couponExpanded, setCouponExpanded] = React.useState(false);
        return (
            <>
                {couponExpanded &&
                    <>
                    <div style={{ zIndex: '99 !important' }} id="myModal" class="modal">
                      <div class="modal-content">
                        <span onClick={() => setCouponExpanded(false)} class="close">&times;</span>
                        <img src={coupon} />
                      </div>
                    
                    </div></>
                }
                {couponShown && !denied &&
                    <div style={{ zIndex: 99 }} class="transition transform fixed z-100 bottom-0 inset-x-0 pb-2 sm:pb-5 opacity-100 scale-100 translate-y-0 ease-out duration-500">
                        <div class="max-w-screen-xl mx-auto px-2 sm:px-4">
                            <div class="p-2 rounded-lg bg-red-900 shadow-lg sm:p-3">
                                <div class="flex items-center justify-between flex-wrap">
                                    <div class="w-0 flex-1 flex items-center">
                                        <p class="ml-3 font-medium text-white truncate">
                                        <span class="lg:hidden">
                                            <span class="rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-1">$250 OFF</span> Services totaling over $2,500.
                                        </span>
                                        <span class="hidden lg:inline text-white-400">
                                            <span class="rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-1">$250 OFF</span>
                                            <span class="xl:hidden">Any Roofing/Repair services totaling over $2,500.</span>
                                            <span class="hidden xl:inline">Any Roofing/Repair services totaling over $2,500.</span>
                                        </span>
                                        </p>
                                    </div>
                                    <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                                        <div style={{ cursor: 'pointer' }} class="rounded-md shadow-sm">
                                            <div onClick={() => {
                                                setCouponExpanded(true)
                                                setCouponShown(false)
                                            }} class="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-900 bg-white hover:text-gray-800 focus:outline-none focus:underline">Learn more</div>
                                        </div>
                                    </div>
                                    <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                                        <button onClick={() => {
                                            setCouponShown(false);
                                            sessionStorage.setItem('couponDenied', true);
                                        }} type="button" class="-mr-1 flex p-2 rounded-md hover:bg-red-800 focus:outline-none focus:bg-red-800" aria-label="Hide banner">
                                            <svg class="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>            
                }
            </>
        )
}
export default Coupon