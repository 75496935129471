import React from "react"
import Yelp from "../images/YELP_LOGO.png"
import Google from "../images/GOOGLE_LOGO.png"

const Footer = () => {
    return (
        <div id="contact">
<div class="bg-gray-100">
   <div class="max-w-6xl m-auto text-gray-800 flex flex-wrap justify-center">
      <div class="p-5 w-64 ">
         <div class="text-xs uppercase text-gray-500 font-medium">Business Hours</div>
         <a class="my-5 block" style={{ textAlign: 'justify' }}>
          <strong>Sun:</strong> 12PM - 5PM <br />
          <strong>Mon:</strong> 7AM - 7PM <br />
          <strong>Tue:</strong> 7AM - 7PM <br />
          <strong>Wed:</strong> 7AM - 7PM <br />
          <strong>Thu:</strong> 7AM - 7PM <br />
          <strong>Fri:</strong> 7AM - 7PM <br />
          <strong>Sat:</strong> 7AM - 7PM
        </a>
      </div>

      <div class="p-5 w-64 ">
         <div class="text-xs uppercase text-gray-500 font-medium">Contact us</div>
         <a class="my-3 block">
           794 County Road 308
          Cleveland, TX 77327
          United States 
          <span class="text-teal-600 text-xs p-1"></span>
        </a>
        <a class="my-3 block" href="mailto:rayburnroofing@gmail.com?subject=Website Inquiry">
          rayburnroofing@gmail.com
          <span class="text-teal-600 text-xs p-1"></span>
        </a> 
        <a class="my-3 block" href="tel:713-865-6014">
          (713) 865-6014
          <span class="text-teal-600 text-xs p-1"></span>
        </a> 
      </div>
   </div>
</div>


<div class="lg:text-center ml-auto mr-auto w-full bg-gray-100">
  <ul class="ml-auto mr-auto mx-auto px-4 md:grid md:grid-cols-3 md:col-gap-1">
    <li class="self-center">
      <div class="flex">
        <a href="https://www.google.com/search?rlz=1C5CHFA_enUS901US901&sxsrf=ALeKk00JTA-Yfp10eIaSsFfOZACcmQFzuw%3A1591258889259&ei=Ca_YXryeD4SMsQX2upKoAg&q=rayburns+roofing&oq=rayburns+roofing&gs_lcp=CgZwc3ktYWIQAzIECCMQJzIECCMQJzIECCMQJzIGCAAQFhAeMgYIABAWEB4yAggmUJIpWKUtYL8xaABwAHgAgAGkA4gB9gSSAQUzLjQtMZgBAKABAaoBB2d3cy13aXo&sclient=psy-ab&ved=0ahUKEwj8xtum3efpAhUERqwKHXadBCUQ4dUDCAw&uact=5#lrd=0x8638a75b1c3dbec1:0x433217217dc153d8,1,,,">
            <img alt='View Reviews for Rayburns Roofing on Google Reviews' class="w-1/4 mx-auto" src={Google} />
        </a>
      </div>
    </li>
    <li class="self-center">
      <div class="flex">
        <a href="https://www.yelp.com/biz/rayburn-s-roofing-cleveland">
            <img alt='View Reviews for Rayburns Roofing on Yelp' class="w-1/4 mx-auto" src={Yelp} />
        </a>
      </div>
    </li>
    <li class="self-center">
      <div class="flex">
        <a href="https://www.bbb.org/us/tx/cleveland/profile/roofing-contractors/rayburns-roofing-0845-90085111/#sealclick" target="_blank" rel="nofollow"><img src="https://seal-southeasttexas.bbb.org/seals/blue-seal-293-61-bbb-90085111.png" style={{ border: 0 }} alt="Rayburn's Roofing BBB Business Review" /></a>
      </div>
    </li>
  </ul>

    </div>


<div class="bg-gray-100 pt-2 ">
   <div class="flex pb-5 px-3 m-auto pt-5 border-t text-gray-800 text-sm flex-col
      md:flex-row max-w-6xl">
      <div class="mt-2">© Copyright {new Date().getFullYear()}. All Rights Reserved.</div>
      <div class="md:flex-auto md:flex-row-reverse mt-2 flex-row flex">

      </div>
   </div>
</div>
</div>
    )
}
export default Footer